<template>
  <div>
    <FileUpload  :name="getName()" :url="getUploadUrl()"
                 @upload="afterUpload"
                 @before-send="beforeSend"
                 :fileLimit="fileLimits"
                 :multiple="isMultiple()"
                accept="image/*,video/*" :maxFileSize="maxFileSize">
    <template #empty>
      <p>Drag and drop files to here to upload.</p> 
    <l-button   @click="openModal"
            nativeType="submit"
            type="info" wide>Embed a video
    </l-button>
            
    </template>
    </FileUpload>

    <div v-if="isMultiple() && value.length" class="el-upload-list--picture-card">
      <draggable :force-fallback="true" v-model="value" group="gallary" @start="drag=true" @end="moveEventfunction" class="d-flex flex-wrap">
        <div v-for="(file, x) in value" :key="'file_'+x"> 
          <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
            <img v-if="file.type === 'image'" alt="" :src="getUrl()+file.image" class="el-upload-list__item-thumbnail">
            <video v-if="file.type === 'video'" controls>
              <source :src="getUrl()+file.image">
              Your browser does not support the video tag.
            </video>
            <div v-if="file.type === 'embeded'" v-html="file.image"> 
            </div>
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handleMultipleCardPreview(file)"><i class="el-icon-zoom-in"></i></span>
              <span class="el-upload-list__item-delete" @click="handleRemove(x)"><i class="el-icon-delete"></i></span>
            </span>
          </div>
        </div>
      </draggable>

    </div>
    <el-dialog :visible.sync="dialogImageVisible">
      <img style="max-width:100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <el-dialog :visible.sync="dialogVideoVisible" @close="closeVideoPreview">
      <video id="video-preview" controls width="650" height="300">
        <source :src="dialogVideoUrl">
        Your browser does not support the video tag.
      </video>
    </el-dialog>
     <embeded-video-modal :visible="embededVideoModalVisibility"
                    :message="'Are you sure you want to delete this client?'"
                    @close="closeModal()"
                    @confirm="addEmbededVideo($event)">
      </embeded-video-modal>
  </div>



</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';
import FileUpload from 'primevue/fileupload';
import draggable from 'vuedraggable';
import EmbededVideoModal from './EmbededVideoModal.vue';


export default {

  name: "PrimeUploader",
  props: {
    elementNum:{
      default:1
    },
    maxFileSize:{
      default:1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default:"240px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
    isEditMode:{
      type: Boolean, 
    }
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
    draggable,
    EmbededVideoModal,
  },

  data() {
    return {
      files:[],
      fileLimits:1,
      dialogImageUrl: '',
      dialogVideoUrl: '',
      disabled: false,
      dialogImageVisible: false,
      dialogVideoVisible: false, 
      embededVideoModalVisibility: false,
      updateListSorting : false,
      editMode: this.isEditMode,
    };
  },
  mounted() { 
    this.fileLimits = this.elementNum;
  },
  updated(){ 
    if(this.updateListSorting && !this.editMode)
        {
          this.moveEventfunction();
          this.updateListSorting = false;
        }
  },
  methods: {
    openModal(){
        this.embededVideoModalVisibility = true;
    },

    addEmbededVideo(event){     
    let newEmbededVideo = {
      'id':null,
      'image':event,
      'sort_order':this.value.length,
      'type':'embeded'
    };  
    this.value.push({...newEmbededVideo});
     this.embededVideoModalVisibility = false; 
    },
     closeModal() {
      this.embededVideoModalVisibility = false;
    },
    moveEventfunction () {
      this.value.map((element, index) => { element.sort_order =  index})
    },
    beforeSend: function (event) {
      this.fileLimits++;
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      resp.data.url.forEach((e, index) => {
        let fileType = event.files[index].type.substr(0, event.files[index].type.indexOf('/'));
        e.type = fileType;
      }) 
      if(this.isMultiple()){
        this.files = this.value;
        this.files = this.files.concat(resp.data.url);
        this.$emit("input", this.files);
      }else{
        this.$emit("input", resp.data.url);
      } 

      this.updateListSorting = true;
    },
    handleCardPreview() {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },
    handleMultipleCardPreview(file) {
      if (file.type == 'image'){
        this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + file.image;
        this.dialogImageVisible = true;
        this.dialogVideoVisible = false;
      }else{
        this.dialogVideoUrl = process.env.VUE_APP_SERVER_IMAGE_URL + file.image;
        this.dialogVideoVisible = true;
        this.dialogImageVisible = false;
      }
    },
    handleRemove(x=1000) {

      if(!this.isMultiple()){
        this.$emit('input', "");
      }else{
        this.value.splice(x, 1);
        this.$emit("input", this.value);
      }
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

    getUploadUrl(){
      if(this.elementNum == 1){
        return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-photo';
      }else{
        return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-multiple-photo';
      }
    },
    isMultiple(){
      return (this.elementNum != 1);
    },
    getName(){
      return (this.isMultiple())? 'photo[]' : 'photo';
    },
    closeVideoPreview(){
      var vid = document.getElementById("video-preview");
      vid.pause();
      this.dialogVideoUrl = null;
    },
   
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}

</style>
