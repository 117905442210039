<template>

  <div class="row" ref="recyclableForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
            <div class=" text-right">
              <l-button
                v-if="$store.getters['auth/haveOneOfPermissions'](['recyclables/save'])"
                @click="$router.push('/recyclables/manage')" type="danger" wide>Manage
              </l-button>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <div  class="el-upload-list--picture-card">
                  <div class="d-flex flex-wrap">
                    <div v-for="(file, x) in formData.images" :key="'file_'+x">
                      <div class="el-upload-list__item" :style="{width:200,height:200}">
                        <img alt="" :src="getUrl()+file.image" class="el-upload-list__item-thumbnail">
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview" @click="handleMultipleCardPreview(file)"><i class="el-icon-zoom-in"></i></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>

      </ValidationObserver>

    </div>
    <el-dialog :visible.sync="dialogImageVisible">
      <img style="max-width:100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip, Dialog} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import draggable from 'vuedraggable';
import PrimeUploader from "../../components/PrimeUploader";
import PrimeMultiUploader from "../../components/PrimeMultiUploader";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    PrimeMultiUploader,
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    'editor': Editor,
    draggable,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
      images : [],
      },
      galleryKey: 0,
      dialogImageVisible: false,
      dialogImageUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.recyclableForm
    });

    this.formTitle = "Recyclables Gallery";
    this.getRecyclablesGallery();

  },


  methods: {
    getRecyclablesGallery() {
      this.axios.post("recyclables/get").then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      })
    },
    handleMultipleCardPreview(file) {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + file.image;
      this.dialogImageVisible = true;
      console.log(this.dialogImageUrl)
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    closeVideoPreview(){
      var vid = document.getElementById("video-preview");
      vid.pause();
      this.dialogVideoUrl = null;
    },
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0 0 3px;
}

.extra-fields > h4 {
  padding: 20px;
}
</style>
