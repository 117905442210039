<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <h5 style="text-align: center">Please  <b>paste</b> video embeded code here</h5>
    <ValidationProvider
                vid="embedded_video"
                rules=""
                name="The Embedded Video"
                v-slot="{ passed, failed,errors }">
          <fg-input type="text"
                    :error="failed ? errors[0]: null"
                    label="Embedded Video"
                    name="embedded_video"
                    fou
                    v-model="embedded_video">
          </fg-input>
        </ValidationProvider>
        <div style="width:200px !important;" v-html="embedded_video">
                  </div>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger"  @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" :disabled="checkDisableButton" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'embeded-video-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      input: "",
      embedded_video : '',
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    }
  },

  computed: {
    checkDisableButton() {
      return !(this.embedded_video.toLowerCase() !== '');
    }
  },

  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm',this.embedded_video);
      this.embedded_video ='';
    }

  }

}
</script>
